import { useState, useEffect } from 'react';
import axios from 'axios';
import { WithContext as ReactTags } from 'react-tag-input';
import logo from './logo.svg';
import './App.css';

axios.defaults.baseURL = 'http://localhost:8888/wp-json/';

function App() {
  const [availableTags, setAvailableTags] = useState([]);
  const [tags, setTags] = useState([]);
  
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  }

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  }

  useEffect(() => {
    async function fetchAvailableTags() {
      // You can await here
      const result = await axios('wp/v2/tags/');
      console.log(result.data);
      const tags = result.data.map(tag => {
        return {
          id: `pp${tag.id}`,
          text: tag.name
        }
      })
      setAvailableTags(tags);
    }
    fetchAvailableTags();
    saveLink();
  },[]);

  const saveLink = () => {
    axios.post('idmsavetolibrary/v1/link', {
      url: 'Fred',
      tags: ['Flintstone']
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  const tagElements = availableTags.map(tag => (
    <li key={tag.id}>
      {tag.text}
    </li>
  ));

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <button onClick={saveLink}>save link</button>
        <ReactTags tags={tags}
          suggestions={availableTags}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          delimiters={delimiters} />
        <ul>
        {tagElements}
        </ul>
      </header>
    </div>
  );
}

export default App;
